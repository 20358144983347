/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Budapest"), React.createElement("p", null, "Pour se loger, Budapest s’articule en cinq quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/hu/budapest/budavar.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Budavár"), " (1er arrondissement), rive droite du Danube : quartier de rues pavées près du château Várhegy."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/hu/budapest/belvaros-lipotvaros.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Belváros"), " (5e), rive gauche : très chic, le centre-ville historique de Budapest autour du Parlement hongrois."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/hu/budapest/terezvaros.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Terézváros"), " (6e), à l’est : le coin des théâtres et des restaurants près de l’Opéra, sur l’avenue Andrássy."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/hu/budapest/erzsebetvaros.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Erzsébetváros"), " (7e), au sud du 6e : ancien quartier juif, avec de nombreux hôtels et des bars alternatifs."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/hu/budapest/jozsefvaros.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Józsefváros"), " (8e), au sud-est : quartier jeune près des bars et clubs de la rue Ráday dans le 9e."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
